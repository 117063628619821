<template lang="pug">
    
div(v-if='Especialistas').pa-0
    //- PORTADA
    v-container(fluid).pa-0 

    //- CAJON DE MEDICOS ESPECIALISTAS
    .d-none.d-md-block
      v-row.justify-center.align-start.griscajon.pa-9
        v-col(cols=12 sm=12 md=12 lg=9)
          h2(v-html='Especialistas.titulo' ).text-center.titulo_verde
          h1(v-html='Especialistas.subtitulo' ).text-center.subtitulo_morado
          v-row.justify-center.align-center.cajondoctores
            v-col(v-for='{nombre, cpm, imagen}, i in Especialistas.medicos' :key='i' cols='4')
              v-img(  :src='imagen'  height='200' contain).superponer_imagen  
              v-row.justify-center.align-center.pb-12
                  v-card(  flat height='80').color_borde_verde
                    h3(v-html='nombre').especialistas_texto_morado.text-center
                    p(v-html='cpm').especialistas_texto_verde.text-center
   
    //- VIDEO PC
    .d-none.d-md-block.pt-1
      v-row.justify-center.align-center.mt-12.pt-12
        video( :src='Contenido.video' width='100%' height='600px' contain  playsinline muted autoplay  loop ) 
    .d-md-none
      v-col(cols=12 md=6)
          v-row.justify-center.align-center.pa-0.d-md-none  
              v-img(contain).pa-0
                  video( contain :src='Contenido.video' width='100%'  autoplay  playsinline muted loop ).pa-0
    //- CV - MEDICOS
    .d-none.d-md-block
      v-row.justify-center.align-center.pt-12
      
        v-col(v-for='{imagen, cargo, cpm, contenido, firma, nombre, redes}, i in Especialistas.cv_medicos' :key='i'  cols='12')
          v-row.justify-center.align-start.mt-12
            v-col(cols=12  md=2)
              v-img(  :src='imagen'  height='100%' contain)
              v-row.justify-center.mt-5(style="z-index: 30")
                v-col(v-for='red, j in redes' :key='j'  cols='3')
                  v-img(style="z-index: 10000"  :src='red.imagen'  height='50' contain @click='redirigir_a(red.enlace)' ).pointer
            
            v-col(cols=12  md=6)
              p(v-html='nombre').nombredoctor
              h3(v-html='cargo').cargo
              p(v-html='contenido').text-justify.mt-5
              v-img(  :src='firma'  height='100' contain).firma
    //- CV - MEDICOS - PC    
    .d-md-none
      
        v-carousel(hide-delimiters=''  height='100%').mt-4
            v-carousel-item(v-for='{imagen, cargo, cpm, contenido, firma, nombre, redes}, i in Especialistas.cv_medicos' :key='i'  cols='10')
              v-row.justify-center.align-start.mt-12.pb-5
                v-col(cols=10)
                  v-img(  :src='imagen'  height='200px' contain)
                  v-row.justify-center
                    v-col(v-for='{imagen, enlace}, i in redes' :key='i'  cols='2').mt-3
                      v-img(  :src='imagen'  height='50px' contain @click='redirigir_a(enlace)' ).pointer
              
                v-col(cols=10  )
                  p(v-html='nombre' style="color: #490383; font-size:20px").text-center
                  h3(v-html='cargo').cargo.text-center
                  p(v-html='contenido').text-justify.mt-5.text-caption
                  v-img(  :src='firma'  height='100' contain).justify-center.align-center       
          
</template>
<script>
  export default {
    methods: {
            redirigir_a(enlace){
                window.open(enlace, '_blank')
            },
        },
  }
</script>
<style lang="sass" scoped>
.pointer
  cursor: pointer
.nombredoctor
  color: #490383

  font-size:25px
.cargo
  color: #00AA7F
  line-height: 2%
  
.firma  
  right: 40%
  margin-top: -3%

.red-social
  img
    width: 23px
    cursor: pointer

.griscajon
  background-color: #F4F4F3
  height: 280px
  margin-bottom: 8%
.superponer_imagen  
  z-index: 2
.cajondoctores
  position: relative
  padding-top: 50px
  height: 80px
  margin-left: 3%
  margin-right: 3%
  
.color_borde_verde
  border: 3px solid #00AA7F !important
  border-radius: 15px !important
  position:absolute
  z-index: 1
  top: -10px
  width: 300px
  

.color_borde_morado
  border: 3px solid #4C137B !important
  border-radius: 50px
  width: 70%
  margin-top: 3em
 
.titulo_verde
  color: #00AA7F
  line-height: 1em
.subtitulo_morado
  color: #490383
  line-height: 0em
.especialistas_texto_verde
  color: #00AA7F
  line-height: 0.5em
.especialistas_texto_morado
  color: #490383
  line-height: 0%
  margin-top: 30px
</style>